import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Menu } from './components/Common/Menu';
import { Footer } from './components/Common/Footer';

// const ThankYou = lazy(() => import('./routes/thankyou'));
const Landing = lazy(() => import('./routes/Landing'));
// const Mission = lazy(() => import('./routes/Mission'));
const Team = lazy(() => import('./routes/Team'));
const Contact = lazy(() => import('./routes/Contact'));
// const PreOrder = lazy(() => import('./routes/PreOrder'));
// const Checkout = lazy(() => import('./routes/checkout'));

const App = () => {
  return (
    <div className='bg-white'>
    <Menu />
    <Suspense>
      <Routes>
        <Route path='/' index element={<Landing />} />
        {/* <Route path='/mission' element={<Mission />} /> */}
        <Route path='/team' element={<Team />} />
        <Route path='/contact' element={<Contact />} />
        {/* <Route path='/preorder' element={<PreOrder />} /> */}
        {/* <Route path='checkout' element={<Checkout/>} /> */}
        {/* <Route path='thankyou' element={<ThankYou />} /> */}
        <Route path='*' element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
    <Footer />
    </div>
  )
}

export default App;