export const Menu = () => {
    return (
        <div className="navbar bg-white flex-wrap justify-between">
            <div className="w-20 sm:w-48 h-20">
                <a href = "/" className="btn btn-ghost w-16 h-16 md:w-20 md:h-20 ">
                    <img src="/Logo/makesenseLogoBlack.png" alt=""/>
                </a>
            </div>
            <div><h1 className="text-black font-semibold text-3xl md:text-6xl">MakeSense</h1></div>
            <div className="flex-none">
                <ul className="menu menu-horizontal px-1 text-black">
                {/* <li><a href="/mission" className="active:bg-white"><button className="border-none btn w-8 md:w-16 bg-white text-black active:bg-white hover:bg-white">Mission</button></a></li> */}
                <li><a href="/team" className="active:bg-white"><button className="border-none btn w-8 md:w-16 bg-white text-black active:bg-white hover:bg-white">Team</button></a></li>
                <li><a href="/contact" className="active:bg-white"><button className="border-none btn w-8 md:w-16 bg-white text-black active:bg-white hover:bg-white">Contact</button></a></li>
                {/* <li><a href="/preorder" className="active:bg-white"><button className="btn bg-black w-16 md:w-32 text-white rounded-xl">PRE-ORDER</button></a></li> */}
                </ul>
            </div>
        </div>
    )
}

